import {useState} from "react";
import {TransactionParams} from "../api/apiTypes";
import {Button} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {CgSpinner} from "react-icons/cg";

interface TransactionSearchProps {
  onParamsChange: any;
  isLoading: boolean;
}

function TransactionSearch({ onParamsChange, isLoading }: TransactionSearchProps) {
  const [carbonCertificateId, setCarbonCertificateId] = useState("");
  const [treeId, setTreeId] = useState("");
  const [aCO2Id, setACO2Id] = useState("");

  const changeCarbonCertificateId = (event: any) => {
    setCarbonCertificateId(event.target.value);
  };
  const changeTreeId = (event: any) => {
    setTreeId(event.target.value);
  };
  const changeACO2Id = (event: any) => {
    setACO2Id(event.target.value);
  };

  const handleSearchChange = () => {
    onParamsChange({
        carbonCertificateId: carbonCertificateId,
        treeId: treeId,
        aCO2Id: aCO2Id,
    })
  };

  return <div
    className={'px-4 py-3 flex flex-col max-w-[800px]'}
    style={{
      backgroundColor: "#80bd64",
      borderRadius: "12px",
    }}
  >
    <h4 className={'text-1xl font-bold mb-2'}>Filter by:</h4>
    <div className={'flex flex-row justify-between'}>
      <div className={'w-full mr-3'}>
        <input
          type="text"
          placeholder="Carbon Certificate ID"
          className="pl-3 py-1.5 mb-2 border rounded w-full  focus:outline-none "
          value={carbonCertificateId}
          onChange={changeCarbonCertificateId}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchChange()}
        />
      </div>
      <div className={'w-full mr-3'}>
        <input
          type="text"
          placeholder="Tree ID"
          className="pl-3 py-1.5 mb-2 border rounded w-full focus:outline-none "
          value={treeId}
          onChange={changeTreeId}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchChange()}
        />
      </div>
      <div className={'w-full'}>
        <input
          type="text"
          placeholder="aCO2 ID"
          className="pl-3 py-1.5 mb-2 border rounded w-full focus:outline-none "
          value={aCO2Id}
          onChange={changeACO2Id}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchChange()}
        />
      </div>
    </div>
    <div className="">
      <Button
        onClick={handleSearchChange}
        style={{
          color: "white",
          height: "40px",
          fontWeight: "700",
          backgroundColor: "#247147",
          maxWidth: "320px",
          width: '100%'
        }}
        icon={<SearchOutlined/>}
        loading={isLoading}
      >
        FILTER TABLE
      </Button>
    </div>
  </div>
}

export default TransactionSearch;
