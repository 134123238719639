import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { EP_URL } from "../../api/apiTypes";
import { DataTableViewProps } from "../../types/components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function DataTableViewAdmin({
  params,
  setParams,
  propertyPaginationList,
}: DataTableViewProps) {
  const [flag, setFlag] = useState<boolean>(false);
  const [numRow, setNumRow] = useState(-1);
  const [id, setID] = useState("");
  const [proponent, setProponent] = useState("");
  const [developer, setDeveloper] = useState("");
  const [information, setInformation] = useState("");
  const [name, setName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [carbonPerYear, setCarbonPerYear] = useState("");
  const [sizePlot, setSizePlot] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableData, setTableData] = useState(propertyPaginationList || []);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    setTableData(propertyPaginationList);
  }, [propertyPaginationList]);

  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortOrder === "asc";

    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");

    const sortedData = [...tableData]?.sort((a, b) => {
      if (a[field] < b[field]) {
        return isAsc ? 1 : -1;
      } else if (a[field] > b[field]) {
        return isAsc ? -1 : 1;
      }
      return 0;
    });

    setTableData(sortedData);
  };
  const renderSortArrow = (field: string) => {
    return sortField === field ? (
      sortOrder === "asc" ? (
        <>&#x21A5;</>
      ) : (
        <>&#x21A7;</>
      )
    ) : (
      ""
    );
  };

  return (
    <>
      <table className="divide-y divide-gray-200 border border-gray">
        <thead style={{ backgroundColor: "#E4ECE5" }}>
          <tr>
            <th
              onClick={() => handleSort("id")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              ID {renderSortArrow("id")}
            </th>
            <th
              onClick={() => handleSort("name")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Name {renderSortArrow("name")}
            </th>
            <th
              onClick={() => handleSort("proponent")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Proponent {renderSortArrow("proponent")}
            </th>
            <th
              onClick={() => handleSort("developer")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Developer {renderSortArrow("developer")}
            </th>
            <th
              onClick={() => handleSort("information")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Information {renderSortArrow("information")}
            </th>
            <th
              onClick={() => handleSort("projectType")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Project Type {renderSortArrow("projectType")}
            </th>
            <th
              onClick={() => handleSort("country")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Country {renderSortArrow("country")}
            </th>
            <th
              onClick={() => handleSort("region")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Region {renderSortArrow("region")}
            </th>
            <th
              onClick={() => handleSort("carbonDebits")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Carbon Debits Per Year {renderSortArrow("carbonDebits")}
            </th>
            <th
              onClick={() => handleSort("plotSize")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              Plot Size {renderSortArrow("plotSize")}
            </th>
            <th
              onClick={() => handleSort("startDate")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              start date {renderSortArrow("startDate")}
            </th>
            <th
              onClick={() => handleSort("endDate")}
              className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer"
            >
              end date {renderSortArrow("endDate")}
            </th>{" "}
            <th className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer">
              Is Published
            </th>
            <th className="py-3 text-center leading-4 font-medium text-xs text-black uppercase tracking-wider w-[500px] bg-[#E4ECE5] cursor-pointer">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {tableData.length > 0 ? (
            tableData.map((listing, index) => (
              <tr key={listing.id} style={{ height: "80px" }}>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="ID..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={id}
                      onChange={(e) => setID(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.id}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Name..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.name}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Proponent..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={proponent}
                      onChange={(e) => setProponent(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.proponent}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Developer..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={developer}
                      onChange={(e) => setDeveloper(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.developer}
                    </span>
                  )}
                </td>
                <td className="py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Information..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={information}
                      onChange={(e) => setInformation(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900 whitespace-pre-wrap"
                    >
                      {listing.information}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Type..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={projectType}
                      onChange={(e) => setProjectType(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.type}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Country..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.country}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Region..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.region}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="Carbon..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={carbonPerYear}
                      onChange={(e) => setCarbonPerYear(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.carbonDebitsPerYear?.toLocaleString()}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="plot..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={sizePlot}
                      onChange={(e) => setSizePlot(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.plotSize?.toLocaleString()}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="start..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {dayjs.utc(listing?.startDate).format("YYYY-MM-DD")}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="end..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {dayjs.utc(listing?.endDate).format("YYYY-MM-DD")}
                    </span>
                  )}
                </td>
                <td className="text-center py-4 whitespace-no-wrap">
                  {flag && numRow === index ? (
                    <input
                      type="string"
                      placeholder="start..."
                      style={{ fontSize: "0.65rem" }}
                      className="px-2 py-1 border rounded w-[65px] focus:outline-none"
                    />
                  ) : (
                    <span
                      style={{ fontSize: "0.65rem" }}
                      className="leading-5 text-gray-900"
                    >
                      {listing.isPublish ? <>✅</> : <>❌</>}
                    </span>
                  )}
                </td>
                <td className="text-center py-1 whitespace-no-wrap text-blue-500 ">
                  <Link
                    to={`/manage-detail/${listing.id}`}
                    className="px-2 py-1 mr-4 text-white rounded  bg-green-600  focus:outline-none"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ border: "none" }}>
              <td
                colSpan={12}
                style={{
                  backgroundColor: "#F4F4F4",
                  height: "380px",
                  borderRadius: "12px",
                  border: "none",
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  color: "#CCC8C8",
                }}
                className="px-6 py-4 text-center"
              >
                <span className="text-gray-500">Empty</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default DataTableViewAdmin;
