import ReactPaginate from "react-paginate";
import { PaginationProps } from "../types/components";

function Pagination(props: PaginationProps) {
  const handlePageClick = (event: any) => {
    props.setParams({ ...props.params, _page: event.selected + 1 });
    if (props?.onPageChange) {
      props.onPageChange(event.selected + 1)
    }
    if (props?.onNextClick) {
      event.selected > props.params._page ? props?.onNextClick() : null;
    }
    if (props?.onPrevClick) {
      event.selected < props.params._page ? props?.onPrevClick() : null;
    }
  };

  return (
    <div className={props.className}>
      <div className="flex flex-row items-center justify-between w-full">
        {props.showXOfAll ? (
          <div className="flex items-center sm:mb-0">
            <div className="text-gray-600">
              <span className="text-[#a33535] mr-2">{props.params._page}</span>of
              <span className="text-[#a33535] ml-2">
              {props.totalPages}
            </span>{" "}
              Page
            </div>
          </div>
        ) : (<div></div>)}
        <div>
          <ReactPaginate
            forcePage={props.params._page - 1}
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={props.totalPages}
            previousLabel="< Previous "
            pageClassName="page-item"
            pageLinkClassName="page-link  !text-[black]"
            previousClassName="page-item"
            previousLinkClassName="page-link !text-[green]"
            nextClassName="page-item  "
            nextLinkClassName="page-link !text-[green]"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link !text-[green]"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
