import {
  DataTableTransactionViewProps,
} from "../../types/components";
import dayjs from "dayjs";
import TableSort from "../tableView/tableSort";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function DataTableViewTransactions({
                                     propertyPaginationList,
                                     params, setParams
                                   }: DataTableTransactionViewProps) {
  const sortedPropertyList = propertyPaginationList?.sort(
    (a: any, b: any) => a.id - b.id
  );

  return (
    <>
      <div
        className="border mx-6 my-3"
        style={{
          borderRadius: "12px",
          backgroundColor: "#F4F4F4",
          fontFamily: "Poppins",
        }}
      >
        <table
          className="min-w-full divide-y divide-gray-200"
          style={{borderRadius: "12px"}}
        >
          <thead style={{backgroundColor: "#E4ECE5", height: "50px"}}>
          <tr>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              <TableSort title={'Carbon Certificate ID'} params={params} field={'carbonCertificateId'} setParams={setParams}/>
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              <TableSort title={'Amount [kg]'} params={params} field={'amount'} setParams={setParams}/>
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              <TableSort title={'Date'} params={params} field={'date'} setParams={setParams}/>
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              <TableSort title={'Tree ID'} params={params} field={'treeId'} setParams={setParams}/>
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              <TableSort title={'aCO2 ID'} params={params} field={'aCO2Id'} setParams={setParams}/>
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              Geolocation
            </th>
            <th
              style={{color: "black", fontSize: "0.65rem"}}
              className="text-center leading-4 font-medium text-gray-800 uppercase tracking-wider w-[500px]"
            >
              NFT at OpenSea
            </th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {sortedPropertyList.length > 0 ? (
            sortedPropertyList.map((listing) => (
              <tr key={listing?._id} style={{height: "70px"}}>
                <td className="text-center whitespace-no-wrap">
                  <span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    <a
                      href={listing?.carbonCertificateImage}
                      target={'_blank'}
                    >{listing?.carbonCertificateId}</a>
                  </span>
                </td>
                <td className="text-center whitespace-no-wrap">
                  <span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    {listing?.amount?.toFixed(2)}
                  </span>
                </td>
                <td className="text-center whitespace-no-wrap">
                  <span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    {dayjs.utc(listing?.date).format('DD. MM. YYYY HH:mm')}
                  </span>
                </td>
                <td className="text-center whitespace-no-wrap">
                  <span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    {listing?.treeId}
                  </span>
                </td>
                <td className="text-center whitespace-no-wrap">
                  <span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    {listing?.aCO2Id}
                  </span>
                </td>
                <td className="text-center whitespace-no-wrap">
                  {listing?.geoLocation?.length ? (<span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    <a
                      style={{
                        color: "white",
                        backgroundColor: "#247147",
                        borderRadius: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      href={`https://www.google.com/maps/search/?api=1&query=${listing?.geoLocation[0]}%2C${listing?.geoLocation[1]}`}
                      target={'_blank'}
                    >Visit</a>
                  </span>) : (<></>)}
                </td>
                <td className="text-center whitespace-no-wrap">
                  {listing?.batchAddress?.length ? (<span style={{fontSize: "0.65rem"}} className="leading-5 text-gray-900">
                    <a
                      style={{
                        color: "white",
                        backgroundColor: "#247147",
                        borderRadius: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      href={`https://opensea.io/assets/matic/${listing?.batchAddress}/${listing?.treeId}`}
                      target={'_blank'}
                    >Visit</a>
                  </span>) : (<></>)}
                </td>
              </tr>
            ))
          ) : (
            <tr style={{border: "none"}}>
              <td
                colSpan={12}
                style={{
                  backgroundColor: "#F4F4F4",
                  height: "380px",
                  borderRadius: "12px",
                  border: "none",
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  color: "#CCC8C8",
                }}
                className="px-6 py-4 text-center"
              >
                <span className="text-gray-500">Empty</span>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DataTableViewTransactions;
