import { useParams } from "react-router-dom";
import Headers1 from "../components/headers1";
import TransactionTable from "../transactions/transactionTable";
import { useEffect, useState } from "react";
import { getPropertyDetails, getTotalCarbonSpent } from "../api/api";
import TransactionSearch from "../components/transactionSearch";
import {TransactionParams} from "../api/apiTypes";
import CoTransExport from "../components/coTransExport";

function TransactionDetail() {
  const {id} = useParams();
  const [projectData, setProjectData] = useState<{
    projectName: string;
    totalCarbonSpent: number;
  }>();
  const [params, setParams] = useState<TransactionParams>({
    _page: parseInt(sessionStorage.getItem('page-transaction') ?? '1'),
    _limit: 10,
    _sort: "",
    _order: "",
    id: undefined,
    projectId: id,
    treeId: "",
    aCO2Id: "",
    carbonCertificateId: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let pd;
      if (id) {
        pd = await getPropertyDetails(Number(id));
      }
      const dataSummary = await getTotalCarbonSpent(Number(id));
      const projectData = {
        projectName: pd?.post?.name ?? 'All',
        totalCarbonSpent: dataSummary?.totalCarbonSpent ?? 0,
      };
      setProjectData(projectData);
    };
    fetchData().then(() => ({}));
  }, [id]);

  function paramsChange(filterParams: any) {
    setParams({
      ...params,
      ...filterParams,
    })
  }

  return (
    <div
      style={{backgroundColor: "#E4F8E4"}}
      className="font-poppins h-full w-full"
    >
      <Headers1/>
      <div className={'mx-12 mt-4 text-3xl font-bold'}>Project transaction details</div>
      <div className="flex flex-col justify-left mt-3 mx-12">
        <div className={'mb-3'}>
          <p className="text-1xl font-bold">
            Carbon debt spent for project(s):&nbsp;{projectData?.projectName}
          </p>
          <p className="text-1xl font-bold">
            Total carbon spent for project(s):&nbsp;{projectData?.totalCarbonSpent?.toFixed(2)} kg
          </p>
        </div>
        <div className={"grid grid-cols-2 gap-4"}>
          <TransactionSearch isLoading={isLoading} onParamsChange={(filterParams: any) => paramsChange(filterParams)}/>
          <CoTransExport isLoading={isLoading} />
        </div>
      </div>

      <TransactionTable
        params={params}
        projectId={Number(id)}
        setParams={(params: TransactionParams) => setParams(params)}
        setIsLoading={(isInnerLoading: any) => setIsLoading(isInnerLoading)}
      />
    </div>
  );
}

export default TransactionDetail;
