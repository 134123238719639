import axios from "axios";
import { Navigate, Outlet } from "react-router-dom";
import { EP_URL } from "./api/apiTypes";
import { useEffect, useState } from "react";

const AdminRoute = () => {
  const [authStatus, setAuthStatus] = useState({
    checking: true,
    isAuthenticated: false,
  });

  useEffect(() => {
    const verifyAuthentication = async () => {
      const isAuthenticated = await isAuthenticatedAsync();
      setAuthStatus({ checking: false, isAuthenticated });
    };

    verifyAuthentication();
  }, []);

  if (authStatus.checking) {
    return <div>Loading...</div>;
  }

  if (!authStatus.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

const isAuthenticatedAsync = async (): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${EP_URL}/api/post/`,
      {},
      { withCredentials: true }
    );
    // Assuming the API response includes a field indicating authentication success
    if (data.status) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Authentication check failed", error);
    return false;
  }
};

export default AdminRoute;
