import React from "react";
import { useNavigate } from "react-router-dom";
import { CardViewProps } from "../../types/components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function ProjectView({ property, adminOnly }: any) {
  const nav = useNavigate();

  const fields = ["check", "version", "sign", "initial", "final", "other"];
  const keys: any = {
    check: "Questionnaire",
    version: "GCS Version",
    sign: "Contract",
    initial: "Initial Estimate Report",
    final: "Final Report",
    other: "Other documents",
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 ">
        <div className="w-full p-6 bg-white">
          <img
            src={property?.imageUrl?.[0]?.path || ""}
            className=""
            alt={property?.imageUrl?.[0]?.filename}
          />
        </div>
        <div className="w-full p-6 bg-white">
          <div className="text-lg">
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">ID Number</span>
              <span>{property.id}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Name</span>
              <span>{property.name}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Proponent</span>
              <span>{property.proponent}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Developer</span>
              <span>{property.developer}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Project Type</span>
              <span>{property.type}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Country</span>
              <span>{property.country}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debit Per Year
              </span>
              <span>{property.carbonDebitsPerYear?.toLocaleString()} kg</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Plot Size</span>
              <span>
                {property.plotSize?.toLocaleString()} m<sup>2</sup>
              </span>
            </div>
            {adminOnly && (
              <>
                <div className="flex justify-between items-center text-gray-700 mb-2">
                  <span className="text-[#313131] font-semibold">
                    System Size
                  </span>
                  <span>{property.systemSize?.toLocaleString()}</span>
                </div>
                <div className="flex justify-between items-center text-gray-700 mb-2">
                  <span className="text-[#313131] font-semibold">
                    Batch number
                  </span>
                  <span>{property.batchNumber}</span>
                </div>
                <div className="flex justify-between items-center text-gray-700 mb-2">
                  <span className="text-[#313131] font-semibold">
                    Batch mint limit
                  </span>
                  <span>{property.batchMintLimit}</span>
                </div>
                <div className="flex justify-between items-center text-gray-700 mb-2">
                  <span className="text-[#313131] font-semibold">
                    Initial supply
                  </span>
                  <span>{property.initialSupply}</span>
                </div>
              </>
            )}
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Plant time</span>
              <span>
                {property.plantTime ? dayjs.utc(property.plantTime).format("YYYY-MM-DD") : ''}
              </span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">Region</span>
              <span>{property.region}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debit start
              </span>
              <span>{property?.startDate ? dayjs.utc(property?.startDate).format("YYYY-MM-DD") : ''}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debit end
              </span>
              <span>{property?.endDate ? dayjs.utc(property?.endDate).format("YYYY-MM-DD") : ''}</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debits generated
              </span>
              <span>{property.carbonDebitsGenerated?.toFixed(2)} kg</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debits available
              </span>
              <span>{property.carbonDebitsAvailable?.toFixed(2)} kg</span>
            </div>
            <div className="flex justify-between items-center text-gray-700 mb-2">
              <span className="text-[#313131] font-semibold">
                Carbon Debits spent
              </span>
              <span>{property.carbonDebitsSpent?.toFixed(2)} kg</span>
            </div>

            {property.information &&
              <>
                <div className="flex flex-col items-start text-gray-700 mb-2">
                  <span className="text-[#313131] font-semibold">
                    Information
                  </span>
                  <span className="text-base whitespace-pre-wrap">{property.information}</span>
                </div>
              </>
            }

            <div
              className="flex justify-end items-center text-gray-700 mt-4"
              onClick={() => nav("/co-trans/" + property.id)}
            >
              <a
                className={
                  "rounded text-white bg-[#247147] hover:bg-[#247147D0] py-1 px-3"
                }
                href={"/co-trans/" + property.id}
              >
                Detailed data for this project
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* DOCUMENT LIST */}
      <div className="">
        <table
          className={"w-full"}
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 1em",
          }}
        >
          <tbody>
            {fields.map((field) => {
              if (property?.[field]?.length) {
                return property[field].map((item: any) => (
                  <tr className="bg-white" key={field + item.filename}>
                    <td className="p-3">{item.filename}</td>
                    <td className="p-3">{keys[field]}</td>
                    <td className="p-3">
                      {dayjs(item?.date ?? property?.registrationDate).format(
                        "YYYY-MM-DD",
                      )}
                    </td>
                    <td className={"p-3 text-center"}>
                      <a
                        className={
                          "px-4 py-2 text-white rounded bg-[#247147] hover:bg-[#247147D0]"
                        }
                        href={item.path}
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ));
              } else {
                return (
                  <tr className="bg-white" key={field}>
                    <td className={"p-4"}>No File Uploaded</td>
                    <td className={"p-4"}>{keys[field]}</td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ProjectView;
