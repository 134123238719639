import { TableSortProps } from "../../types/components";

function TableSort({ title, params, setParams, field }: TableSortProps) {
  const handleSortClick = (column: string, direction: string) => {
    setParams({
      ...params,
      _sort: column,
      _order: direction,
    });
  };

  return (
    <div className="flex items-center flex-row justify-center">
      {title}
      <div className="flex flex-col ml-5">
        <span
          className={`ml-1 cursor-pointer text-xs ${
            params._order === "asc" && params._sort === field
              ? "text-blue-500"
              : ""
          }`}
          onClick={() => {
            params._order === "asc" && params._sort === field
              ? handleSortClick("", "")
              : handleSortClick(field, "asc");
          }}
        >
          ▲
        </span>
        <span
          className={`ml-1 cursor-pointer text-xs ${
            params._order === "desc" && params._sort === field
              ? "text-blue-500"
              : ""
          }`}
          onClick={() => {
            params._order === "desc" && params._sort === field
              ? handleSortClick("", "")
              : handleSortClick(field, "desc");
          }}
        >
          ▼
        </span>
      </div>
    </div>
  );
}

export default TableSort;
