import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getPropertyDetails } from "../api/api";
import { PropertyList, EP_URL } from "../api/apiTypes";
import Headers from "../components/headers1";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectView from "../components/project/projectView";

function ManageDetail() {
  const { id } = useParams();
  const [property, setProperty] = useState<PropertyList>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const nav = useNavigate();

  const handleOk = () => {
    goDelete(property)
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function fetchPropertyDetails() {
    if (id) {
      const propertyDetails = await getPropertyDetails(Number(id));
      if (propertyDetails) setProperty(propertyDetails.post);
    }
  }

  useEffect(() => {
    fetchPropertyDetails();
  }, [id]);

  if (!property) {
    return <div>Loading...</div>;
  }

  function goDelete(t: any) {
    axios
      .post(`${EP_URL}/api/post/delete`, { params: t })
      .then((response) => {
        nav("/manage");
        toast.success("Successfully Deleted!", {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error("Sth went wrong during request!", {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
      });
  }

  async function goDuplicate(id: number) {
    await axios
      .post(`${EP_URL}/api/post/duplicate`, { id })
      .then((res) => {
        toast.success("Successfully created a duplicate!", {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
        if (res?.data?.data?.id) {
          nav(`/manage-detail/${res?.data?.data?.id}`);
        }
      })
      .catch((error) => {
        toast.error("Sth went wrong during request!", {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
      });
  }

  return (
    <div className={"bg-[#F4F4F4] pb-5"}>
      <Headers></Headers>

      <div className="mx-6 md:mx-auto max-w-[1400px] px-4">
        <div className="mt-6 text-right">
          <Link
            to="/manage"
            className="px-4 py-2 text-black rounded focus:outline-none"
          >
            <CloseOutlined />
          </Link>
        </div>
        <div className={"flex justify-between py-6"}>
          <p className="text-2xl font-bold text-[#247147]">
            Project Summary {!property.isPublish && "[Draft]"}
          </p>
          <div>
            <Link to={`/change-item/${property.id}`}>
              <button
                className="px-2 mr-8 py-1 text-white bg-[#247147] hover:bg-[#247147D0] rounded focus:outline-none w-[100px]"
              >
                Edit
              </button>
            </Link>

            <button
              onClick={() => goDuplicate(Number(property.id))}
              className="px-2 mr-8 py-1 text-white bg-[#247147] hover:bg-[#247147D0] rounded focus:outline-none w-[100px]"
            >
              Duplicate
            </button>

            <button
              onClick={() => setIsModalOpen(true)}
              className="px-2 py-1 text-white bg-red-600 hover:bg-red-400 rounded focus:outline-none w-[100px]"
            >
              Delete
            </button>
          </div>
          <Modal
            footer={null}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ textAlign: "center" }}
          >
            <div>
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Are you sure to delete this?
              </p>
              <div className={"mt-8"}>
                <button
                  onClick={() => handleCancel()}
                  className="px-2 mr-5 py-1 text-black rounded border bg-white focus:outline-none w-[100px]"
                >
                  No
                </button>
                <button
                  onClick={() => goDelete(property.id)}
                  style={{backgroundColor: "#D11010"}}
                  className="px-2 py-1 text-white bg-red-600 rounded focus:outline-none w-[100px]"
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        </div>

        <ProjectView property={property} adminOnly={true} />
      </div>
    </div>
  );
}

export default ManageDetail;
