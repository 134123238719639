import {useState} from "react";
import {TableSearchProps} from "../../types/components";
import {Select, Button} from "antd";
import {countries} from "countries-list";
import {SearchOutlined} from "@ant-design/icons";

function TableSearch({params, setParams, loading}: TableSearchProps) {
  const Org_countryNames = Object.values(countries).map(
    (country) => (country.name, country.name)
  );
  const all_countries = ["All"].concat(Org_countryNames);
  const [id, setID] = useState("");
  const [proponent, setProponent] = useState("");
  const [name, setName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [carbonDebits, setCarbonDebits] = useState("");
  const [plotSize, setPlotSize] = useState("");

  const changeID = (event: any) => {
    setID(event.target.value);
  };

  const changeProponent = (event: any) => {
    setProponent(event.target.value);
  };

  const changeName = (event: any) => {
    setName(event.target.value);
  };

  const changeCountry = (event: any) => {
    setCountry(event);
  };

  const changeRegion = (event: any) => {
    setRegion(event.target.value);
  };

  const changeCarbonn = (event: any) => {
    setCarbonDebits(event.target.value);
  };

  const changePlotSize = (event: any) => {
    setPlotSize(event.target.value);
  };
  const handleSearchChange = (event: any) => {
    setParams((prevParams) => ({
      ...prevParams,
      id: id,
      proponent: proponent,
      name: name,
      type: projectType,
      country: country === "All" ? "" : country,
      region: region,
      carbonDebits: carbonDebits ? parseFloat(carbonDebits) : "",
      plotSize: plotSize ? parseFloat(plotSize) : "",
      _page: 1,
    }));
  };

  return (
    <div className="p-3 rounded-xl bg-[#80bd64]">
      <div
        className="mb-3 font-medium text-lg"
        style={{ fontFamily: "Poppins" }}
      >
        Search by:
      </div>
        <div className="grid grid-cols-4 2xl:grid-cols-1 gap-2.5">
            <input
                type="text"
                placeholder="Id..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={id}
                onChange={changeID}
            />
            <input
                type="text"
                placeholder="Proponent..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={proponent}
                onChange={changeProponent}
            />
            <input
                type="text"
                placeholder="Name..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={name}
                onChange={changeName}
            />
            <input
                type="text"
                placeholder="Region..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={region}
                onChange={changeRegion}
            />
            <input
                type="text"
                placeholder="Carbon..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={carbonDebits}
                onChange={changeCarbonn}
            />
            <input
                type="text"
                placeholder="Plot size..."
                className="px-3 py-2 w-full rounded focus:outline-none"
                value={plotSize}
                onChange={changePlotSize}
            />
          <Select
            placeholder="Project Type"
            onChange={setProjectType}
            className="py-2 bg-white w-full rounded focus:outline-none"
            style={{height: 41.6 }}
            variant={"borderless"}
            options={[
              {value: "", label: "All"},
              {value: "Agroforestry", label: "Agroforestry"},
              {value: "Reforestation", label: "Reforestation"},
            ]}
          />
          <Select
            showSearch
            placeholder={`Country`}
            onChange={changeCountry}
            // value={country}
            className="py-2 bg-white w-full rounded focus:outline-none"
            style={{height: 41.6 }}
            variant={"borderless"}
            options={all_countries.map((item) => ({
              label: item,
              value: item === "All" ? "" : item,
            }))}
          />
        </div>

        <div className="py-2 mt-2 text-center">
          <button
            disabled={loading}
              onClick={handleSearchChange}
              className="text-white font-bold h-10 w-full bg-[#247147] hover:!bg-[#247147D0] rounded focus:outline-none"
          >
            <SearchOutlined className="mr-2"/>
            <span>{ loading ? 'LOADING' : 'FIND PROJECT'}</span>
        </button>
      </div>
    </div>
  );
}

export default TableSearch;
