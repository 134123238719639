import {useRef, useState} from "react";
import {Button, DatePicker} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";
import {exportCoTransData} from "../api/api";
import CsvDownloader from 'react-csv-downloader';
import {useParams} from "react-router-dom";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface SearchProps {
  isLoading: boolean;
}

function CoTransExport({ isLoading }: SearchProps) {
  const {id} = useParams();
  const [dateFrom, setDateFrom] = useState<Dayjs | undefined>(dayjs().utc().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | undefined>(dayjs().utc().endOf('month'));

  const handleChangeAll = async () => {
    const data = await exportCoTransData({
        dateFrom: null,
        dateTo: null,
        id: !!id ? Number(id) : undefined,
      })

    if (!data.length) {
      throw 'abc'
      // return null;
    }

    return data.map((v: any) => ({
      ...v,
      geoLocation: v?.geoLocation?.join(', '),
    }));
  }

  const handleChange = async () => {
    const data = await exportCoTransData({
        dateFrom: dateFrom?.startOf('day').toDate(),
        dateTo: dateTo?.endOf('day').toDate(),
        id: !!id ? Number(id) : undefined,
      })

    if (!data.length) {
      throw 'abc'
      // return null;
    }

    return data.map((v: any) => ({
      ...v,
      geoLocation: v?.geoLocation?.join(', '),
    }));
  }

  const columns = [
    {
      id: 'projectId',
      displayName: 'Project ID'
    },
    {
      id: 'treeId',
      displayName: 'Tree ID'
    },
    {
      id: 'carbonCertificateId',
      displayName: 'Carbon Certificate Id'
    },
    {
      id: 'amount',
      displayName: 'Amount [kg]'
    },
    {
      id: 'txHash',
      displayName: 'txHash'
    },
    {
      id: 'aCO2Id',
      displayName: 'aCO2 Id'
    },
    {
      id: 'batchAddress',
      displayName: 'Batch Address'
    },
    {
      id: 'date',
      displayName: 'Date'
    },
    {
      id: 'geoLocation',
      displayName: 'geolocation'
    },
  ];

  return <div
    className={'px-4 py-3 flex flex-col max-w-[800px]'}
    style={{
      backgroundColor: "#80bd64",
      borderRadius: "12px",
    }}
  >
    <h4 className={'text-1xl font-bold mb-2'}>Export to .csv</h4>
    <div className={'w-full flex flex-row justify-between'}>
      <DatePicker
        className="w-full mr-1"
        onChange={e => setDateFrom(e)}
        style={{
          height: 40,
          borderRadius: 8,
          backgroundColor: "white",
        }}
        allowClear={false}
        value={dateFrom}
        onKeyDown={(e) => e.key === 'Enter' && handleChange}
      />
      <DatePicker
        className="w-full ml-1"
        onChange={e => setDateTo(e)}
        style={{
          height: 40,
          borderRadius: 8,
          backgroundColor: "white",
        }}
        allowClear={false}
        value={dateTo}
        onKeyDown={(e) => e.key === 'Enter' && handleChange}
      />
    </div>
    <div className="mt-2 flex flex-row justify-end">
      <CsvDownloader
        filename={`cotrans-ALL-${new Date().toISOString()}`}
        extension=".csv"
        separator=";"
        wrapColumnChar={"\""}
        columns={columns}
        text="DOWNLOAD ALL"
        datas={handleChangeAll}
        className="mr-2"
      >
        <Button
          style={{
            color: "white",
            height: "40px",
            fontWeight: "700",
            backgroundColor: "#247147",
            width: '100%'
          }}
          icon={<DownloadOutlined />}
          loading={isLoading}
        >
          DOWNLOAD ALL
        </Button>
      </CsvDownloader>
        <CsvDownloader
          filename={`cotrans-${id}-${new Date().toISOString()}`}
          extension=".csv"
          separator=";"
          wrapColumnChar={"\""}
          columns={columns}
          text="DOWNLOAD SELECTED"
          datas={handleChange}
        >
          <Button
            style={{
              color: "white",
              height: "40px",
              fontWeight: "700",
              backgroundColor: "#247147",
              width: '100%'
            }}
            icon={<DownloadOutlined />}
            loading={isLoading}
          >
            DOWNLOAD SELECTED
          </Button>
        </CsvDownloader>
    </div>
  </div>
}

export default CoTransExport;
