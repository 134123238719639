import "react-toastify/dist/ReactToastify.css";
import ProjectAddEdit from "../components/project/projectAddEdit";
import Headers1 from "../components/headers1";

function Change() {
  return (
    <div>
      <Headers1></Headers1>

      <div className="mt-16 mb-4">
        <div className="max-w-3xl mx-auto">
          <div className="page-title">Edit Project</div>
        </div>
      </div>

      <ProjectAddEdit />
    </div>
  );
}

export default Change;
