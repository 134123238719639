import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ListingParams, PropertyList, EP_URL } from "../api/apiTypes";
import { getPropertyListings } from "../api/api";
import DataTableView from "../components/tableView/tableViewAdmin";
import Pagination from "../components/pagination";
import Headers1 from "../components/headers1";
import { Row, Col, Typography, Input, Select } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { countries } from "countries-list";

const { Text } = Typography;

function Manage() {
  
  const Org_countryNames = Object.values(countries).map(
    (country) => (country.name, country.name)
  );

  const all_countries = ["All"].concat(Org_countryNames);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [propertyPaginationList, setPropertyPaginationList] = useState<
    PropertyList[]
  >([]);
  const [searchWord, setSearchWord] = useState("");
  const [params, setParams] = useState<ListingParams>({
    _page: parseInt(sessionStorage.getItem('page-manage') ?? '1'),
    _limit: 10,
    _sort: "",
    _order: "",
    id: "",
    proponent: "",
    name: "",
    type: "",
    country: "",
    region: "",
  });

  async function fetchSortedPaginatedListings() {
    const sortedPaginatedListings = await getPropertyListings(params);
    setPropertyPaginationList(sortedPaginatedListings?.data?.post);
    const totalPageNum = sortedPaginatedListings?.data?.totalNum;
    if (totalPageNum) setTotalPages(Math.ceil(totalPageNum / params._limit));
    else setTotalPages(1);
  }

  const handleChange = (value: string) => {
    setParams((prevParams) => ({
      ...prevParams,
      type: value,
    }));
  };
  const handleChange1 = (value: string) => {
    if (value === "All") {
      setParams((prevParams) => ({
        ...prevParams,

        country: "",
      }));
    } else {
      setParams((prevParams) => ({
        ...prevParams,

        country: value,
      }));
    }
  };

  const search = () => {
    setParams((prevParams) => ({
      ...prevParams,
      name: searchWord,
    }));
  };

  useEffect(() => {
    fetchSortedPaginatedListings();
  }, [params]);

  return (
    <>
      <div
        className="bg-[#F1F1F1] px-6 pb-8"
      >
        <Headers1></Headers1>
        <Row className="mt-12 mb-12">
          <Col span={8}>
            <Text
              style={{
                color: "#5E8A72",
                fontSize: 36,
                fontFamily: "Poppins",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Admin Dashboard
            </Text>
          </Col>
          <Col span={16}>
            <Row justify="end">
              <button
                style={{
                  backgroundColor: "#247147",
                  borderRadius: 7,
                  color: "white",
                  width: 222,
                  height: 48,
                }}
              >
                <div style={{display: "inline-flex"}}>
                  <div>
                    <PlusOutlined/>
                  </div>
                  <div
                    style={{
                      marginLeft: 10,

                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      wordWrap: "break-word",
                    }}
                  >
                    <Link to={"/add"} className="text-white">
                      Add New Project
                    </Link>
                  </div>
                </div>
              </button>
            </Row>
          </Col>
        </Row>
        <Row
          className="flex justify-between items-center"
        >
          <Col>
            <div style={{display: "inline-flex", alignItems: "center"}}>
              <div style={{width: 280, marginRight: 20}}>
                <Input
                  placeholder=""
                  prefix={<SearchOutlined/>}
                  style={{height: 42}}
                  onChange={(e) => setSearchWord(e.target.value)}
                  value={searchWord}
                />
              </div>
              <button
                style={{
                  backgroundColor: "#247147",
                  borderRadius: 7,
                  color: "white",
                  width: 101,
                  height: 42,
                }}
                onClick={search}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  Search
                </div>
              </button>
            </div>
          </Col>

          <Col>
            <div style={{display: "inline-flex", alignItems: "center"}}>
              <div style={{marginRight: 20, fontFamily: "Poppins"}}>
                <Text>Filter by:</Text>
              </div>
              <Select
                placeholder="Project Type"
                onChange={handleChange}
                className="bg-white py-2 border rounded w-64 focus:outline-none h-[42px]"
                variant={"borderless"}
                options={[
                  {value: "", label: "All"},
                  {value: "Agroforestry", label: "Agroforestry"},
                  {value: "Reforestation", label: "Reforestation"},
                ]}
              />
              <Select
                placeholder="All"
                className="bg-white py-2 border rounded w-64 focus:outline-none h-[42px] ml-2"
                onChange={handleChange1}
                variant={"borderless"}
                options={all_countries.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
          </Col>
        </Row>
        <div className="w-full mt-4">
          <Pagination
            totalPages={totalPages}
            _page={params._page}
            params={params}
            setParams={setParams}
            onPageChange={(v) =>
              sessionStorage.setItem("page-manage", String(v))
            }
            showXOfAll={false}
          />
        </div>
        <div className="flex justify-between my-2">
          <DataTableView
            propertyPaginationList={propertyPaginationList}
            params={params}
            setParams={setParams}
          />
        </div>
        <div className="w-full mt-2">
          <Pagination
            totalPages={totalPages}
            _page={params._page}
            params={params}
            setParams={setParams}
            onPageChange={(v) =>
              sessionStorage.setItem("page-manage", String(v))
            }
            showXOfAll={true}
          />
        </div>
      </div>
    </>
  );
}

export default Manage;
