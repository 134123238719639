import { useEffect, useState } from "react";
import { TransactionParams, Transactions } from "../api/apiTypes";
import { getTransactions } from "../api/api";
import Pagination from "../components/pagination";
import DataTableViewTransactions from "../components/tableViewTransactions/tableViewTran";

interface Props {
  params: TransactionParams;
  projectId?: number;
  setParams: any;
  setIsLoading: any
}

function TransactionTable({ params, projectId, setParams, setIsLoading }: Props)  {
  const [totalPages, setTotalPages] = useState<number>(0);

  const [transactionPaginationList, setTransactionPaginationList] = useState<
    Transactions[]
  >([]);
  async function fetchData() {
    setIsLoading(true);
    const sortedPaginatedListings = await getTransactions(params);
    setTransactionPaginationList(sortedPaginatedListings?.post || []);
    const pages = sortedPaginatedListings?.totalNum ? Math.ceil(sortedPaginatedListings?.totalNum / params._limit) : 1;
    setTotalPages(pages);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <div style={{backgroundColor: "#E4F8E4"}} className="font-poppins">
      <div className="flex justify-between mb-2 mx-6 ">
        <div>
          <DataTableViewTransactions
            propertyPaginationList={transactionPaginationList}
            params={params}
            setParams={setParams}
          />
          <Pagination
            className="mx-6"
            totalPages={totalPages === 0 ? 1 : totalPages}
            _page={params._page}
            params={params}
            setParams={setParams}
            onPageChange={(v) => sessionStorage.setItem('page-transaction', String(v))}
          />
        </div>
      </div>
      <div className="w-full sm:w-auto pb-8"></div>
    </div>
  );
}

export default TransactionTable;
