import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Input, Modal, Form } from "antd";
import axios from "axios";
import { useCookies } from "react-cookie";
import { EP_URL } from "../api/apiTypes";

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  color: "black",
  height: "72px",
  fontSize: 24,
  paddingInline: 100,
  lineHeight: "72px",
  borderBottom: "1px solid grey",
  background:
    "linear-gradient(257deg, #E5FFEA 0%, rgba(224.19, 255, 229.12, 0) 100%)",
};

function Headers() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies(["token"]);
  const [user, setUser] = useState("");

  const verifyCookie = async () => {
    try {
      const { data } = await axios.post(
        `${EP_URL}/api/post/`,
        {},
        { withCredentials: true }
      );
      if (!data.status) {
        removeCookie("token", { path: "/" });
        navigate("/");
      } else {
        setUser(data.user);
      }
    } catch (error) {
      removeCookie("token", { path: "/" });
      navigate("/");
    }
  };

  useEffect(() => {
    verifyCookie();
  }, [navigate, removeCookie]);

  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [isEmptyNAME] = useState(false);

  const changeUsername = (event: any) => {
    setUsername(event.target.value);
  };
  const changePassword = (event: any) => {
    setPassword(event.target.value);
  };

  const onFinish = (values: any) => {};

  const onFinishFailed = (errorInfo: any) => {};

  async function login() {
    try {
      const { data } = await axios.post(
        `${EP_URL}/api/post/login/`,
        { username, password },
        { withCredentials: true }
      );
      const { success } = data;

      if (success) {
        navigate("/manage");
      } else {
        console.log("Error logging in");
        alert("Error logging in");
      }
    } catch (error) {
      alert("Error logging in, check your credentials");
      console.error("Error:", error);
    }
  }


  type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Layout>
      <Header style={headerStyle}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="../../logo.png"
            style={{ margin: "0", padding: "0", width: "44px", height: "42px" }}
            alt="logo"
          />
          <span
            style={{
              paddingLeft: "10px",
              color: "#116C20",
              fontSize: "24px",
              fontFamily: "Poppins",
              fontWeight: "600",
            }}
          >
            Global Carbon Standard
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: "18px",
            right: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className=" flex space-x-6 ">
            {user ? (
              <div className="mt-[-15px]">
                <Link to={"/manage"}>
                  <button
                    className="bg-[#247147] hover:bg-[#247147D0]"
                    style={{
                      display: "flex",
                      fontFamily: "Poppins",
                      marginTop: "15px",
                      alignItems: "center",
                      textAlign: "center",
                      color: "white",
                      height: "36px",
                      fontSize: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                      }}
                      className="px-10"
                    >
                      MANAGE
                    </p>
                  </button>
                </Link>
              </div>
            ) : (
              <button
                onClick={showModal}
                className="bg-[#247147] hover:bg-[#247147D0]"
                style={{
                  display: "flex",
                  fontFamily: "Poppins",
                  alignItems: "center",
                  textAlign: "center",
                  color: "white",
                  width: "165px",
                  height: "36px",
                  fontSize: "20px",
                  borderRadius: "10px",
                }}
              >
                <p style={{ paddingLeft: "58.61px", fontFamily: "Poppins" }}>
                  Login
                </p>
              </button>
            )}
          </div>

          <Modal
            footer={null}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ textAlign: "center", position: "absolute", right: "2%" }}
          >
            <div style={{}}>
              <div style={{ display: "inline-flex", marginLeft: -140 }}>
                <div style={{}}>
                  <img
                    src="../../Rectangle5.png"
                    width="150"
                    height="130"
                    alt="rectangle image"
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginLeft: 25,
                  }}
                >
                  <img src="../../admin.png" alt="admin" />
                  <div
                    style={{
                      marginLeft: 10,
                      color: "#262626",
                      fontSize: 24,
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    Admin Login
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 70, marginTop: -30 }}>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item<FieldType>
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 350, height: 40 }}
                      placeholder="Username"
                      value={username}
                      onChange={changeUsername}
                    />
                  </Form.Item>
                  <Form.Item<FieldType>
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ width: 350, height: 40 }}
                      placeholder="Password"
                      value={password}
                      onChange={changePassword}
                    />
                    {isEmptyNAME && (
                      <div className="text-[red]  text-Poppins">Input Name</div>
                    )}
                  </Form.Item>
                  <button
                    onClick={login}
                    style={{
                      backgroundColor: "#247147",
                      borderRadius: 7,
                      color: "white",
                      width: 150,
                      height: 40,
                      marginLeft: -40,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      Login
                    </div>
                  </button>
                </Form>
              </div>
            </div>
          </Modal>
        </div>
      </Header>
    </Layout>
  );
}

export default Headers;
