import { Link } from "react-router-dom";
import {DataTableViewProps, PaginationProps} from "../../types/components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function DataTableView({
  propertyPaginationList,
  debitsData,
}: DataTableViewProps) {
  function getDebitsDetailsData(id: number) {
    return debitsData?.find((data: any) => data.id === id) ?? 0;
  }

  return (
    <>
      <div
        className={"border rounded-xl bg-[#F4F4F4] relative"}
        style={{ fontFamily: "Poppins" }}
      >
        <table
          className="min-w-full divide-y divide-gray-200 rounded-xl overflow-x-auto block"
        >
          <thead>
            <tr>
              <th className="table-header min-w-10 2xl:w-[7vw] sticky left-0">
                ID
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Name
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Developer
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Project Type
              </th>
              <th className="table-header min-w-[230px] 2xl:w-[15vw]">
                Information
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Country
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Region
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Carbon Debits Available <span className="lowercase">[kg]</span>
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Carbon Debits Spent <span className="lowercase">[kg]</span>
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Carbon Debits Generated <span className="lowercase">[kg]</span>
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Carbon Seq. Per Year <span className="lowercase">[kg]</span>
              </th>
              <th className="table-header min-w-10 2xl:w-[7vw]">
                Plot Size <span className="lowercase">[<span>m<sup>2</sup></span>]</span>
              </th>
              <th className="table-header min-w-[4.5rem] 2xl:w-[7vw]">
                Start Date
              </th>
              <th className="table-header min-w-[4.5rem] 2xl:w-[7vw]">
                End Date
              </th>
              <th className="table-header sticky right-0 2xl:w-[7vw]">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {propertyPaginationList?.length > 0 ? (
              propertyPaginationList.map((listing) => (
                <tr key={listing?.id} className="h-20">
                  <td className="table-data sticky left-0">
                    {listing?.id}
                  </td>
                  <td className="table-data">
                    {listing?.name}
                  </td>
                  <td className="table-data">
                    {listing?.developer}
                  </td>
                  <td className="table-data">
                    <span>{listing?.type}</span>
                  </td>
                  <td className="table-data !px-2.5 !text-left whitespace-pre-wrap">
                    <span>{listing?.information}</span>
                  </td>
                  <td className="table-data">
                    <span>{listing?.country}</span>
                  </td>
                  <td className="table-data">
                    <span>{listing?.region}</span>
                  </td>
                  <td className="table-data">
                  {new Intl.NumberFormat().format((getDebitsDetailsData(Number(listing?.id))?.debitsAvailable ?? 0)?.toFixed(0))}
                  </td>
                  <td className="table-data">
                    {new Intl.NumberFormat().format((getDebitsDetailsData(Number(listing?.id))?.debitsSpent ?? 0)?.toFixed(0))}
                  </td>
                  <td className="table-data">
                    {new Intl.NumberFormat().format((getDebitsDetailsData(Number(listing?.id))?.debitsGenerated ?? 0)?.toFixed(0))}
                  </td>
                  <td className="table-data">
                    {new Intl.NumberFormat().format(Number(listing?.carbonDebitsPerYear ?? 0))}
                  </td>
                  <td className="table-data">
                    {Number(listing?.plotSize ?? 0)}
                  </td>
                  <td className="table-data ">
                    {dayjs.utc(listing?.startDate).format('YYYY-MM-DD')}
                  </td>
                  <td className="table-data">
                    {dayjs.utc(listing?.endDate).format('YYYY-MM-DD')}
                  </td>
                  <td className="text-center sticky right-0 bg-white">
                    <Link
                      to={`/property-detail/${listing?.id}`}
                      className="px-2 py-1 text-white rounded bg-[#247147] hover:bg-[#247147D0] focus:outline-none mx-2"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-0">
                <td
                  colSpan={15}
                  style={{ fontFamily: "Poppins" }}
                  className="h-96 border-0 text-3xl rounded-xl bg-[#F4F4F4] text-[#CCC8C8] px-6 text-center"
                >
                  <span className="text-gray-500">Empty</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DataTableView;
