import axios, { AxiosResponse } from "axios";
import {
  ListingParams,
  PropertyList,
  PropertyListResponse,
  EP_URL,
  Transactions,
  TransactionParams,
  TransactionResponse,
  TransactionResponseData,
} from "./apiTypes";
import {toast} from "react-toastify";

const api = axios.create({
  baseURL: EP_URL,
  withCredentials: false,
  httpsAgent: {
    rejectUnauthorized: false,
  },
});

export async function changeCount(params: any): Promise<any> {
  try {
    const response = await api.post(`/api/post/changeCount`, params);
    return response;
  } catch (error) {
    console.error("Error changing count:", error);
    return { data: [], headers: undefined };
  }
}

export async function getPropertyDetails(
  id?: number
): Promise<PropertyList | null> {
  try {
    const response: AxiosResponse<PropertyList> = await api.get(
      `/api/post/get/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching property details:", error);
    return null;
  }
}

export async function getTransactions(
  params: TransactionParams
): Promise<TransactionResponseData | null> {
  try {
    const temp = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== "")
    );
    const response: AxiosResponse<TransactionResponse> = await api.get(
      `/api/trans/get`, {
        params: temp,
      }
    );
    return response.data as any as TransactionResponseData;
  } catch (error) {
    console.error("Error fetching property details:", error);
    return null;
  }
}


export async function getTotalCarbonSpent(
  ProjectId?: number | null
): Promise<any> {
  try {
     const response: AxiosResponse<TransactionResponse> = await api.get(
      `/api/trans/summary${ProjectId ? '/' + ProjectId : ""}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching property details:", error);
    return null;
  }
}

export async function getCount(): Promise<any> {
  try {
    const response = await api.get(`/api/post/getCount`);
    return response.data;
  } catch (error) {
    console.error("Error fetching count:", error);
    return null;
  }
}

export async function getPropertyListings(
  params: ListingParams
): Promise<PropertyListResponse> {
  try {
    let temp = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== "")
    );
    const response: PropertyListResponse = await api.get(`/api/post/get`, {
      params: temp,
    });
    return response;
  } catch (error) {
    console.error("Error fetching property listings:", error);
    return { data: [], headers: undefined };
  }
}

export async function exportCoTransData(params: any) {
  try {
    const response = await api.get(`/api/trans/export`, {params});
    if (response.data.length && response.status === 200) {
      return response.data
    } else if (response.data.length === 0 && response.status === 200) {
      toast.error("The requested interval does not contain any data!");
    } else {
      toast.error("An error occurred!");
    }
    return [];
  } catch (e) {
    console.error(e)
    toast.error("An error occurred!");
  }
  return [];
}

export async function updateProperty(data?: PropertyList) {
  try {
    const response = await axios.post(`${EP_URL}/api/post/change`, {
      params: data,
    });
    if (response && response.status === 200) {
      toast.success("Successfully Updated!", {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });

      return response;
    }
  } catch (error) {
    console.error("Error updating exiting property:", error);
    toast.error("Error updating exiting property. Please try again.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return {};
  }
}

export async function createProperty(data?: PropertyList) {
  try {
    const response: PropertyListResponse = await api.post(
      `/api/post/add`,
      data,
    );
    toast.success("Successfully Created!", {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "light",
    });
    return response;
  } catch (error) {
    console.error("Error adding new property:", error);
    toast.error("Error adding new property. Please try again.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return {};
  }
}
