import React from "react";
import Headers1 from "../components/headers1";
import ProjectAddEdit from "../components/project/projectAddEdit";

function Add() {
  return (
    <>
      <div>
        <Headers1></Headers1>

        <div className="mt-16 mb-4">
          <div className="max-w-3xl mx-auto">
            <div className="page-title">
              Add New Project
            </div>
          </div>
        </div>

        <ProjectAddEdit />
    </div>
    </>
  );
}

export default Add;
