import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import PropertyDetail from "./table/propertyDetails";
import Index from "./table/index";
import Add from "./table/add";
import Manage from "./table/manage";
import ManageDetail from "./table/manageDetails";
import Change from "./table/change";
import {ToastContainer} from "react-toastify";
import TransactionDetail from "./table/transactionDetails";
import AdminRoute from "./AdminRoutes";

const App = () => {
    return (
        <>
            <ToastContainer position="top-right"/>
            <Router>
                <Routes>
                    <Route element={<AdminRoute/>}>
                        <Route path="/manage" element={<Manage/>}/>
                        <Route path="/add" element={<Add/>}/>
                        <Route path="/manage-detail/:id" element={<ManageDetail/>}/>
                        <Route path="/change-item/:id" element={<Change/>}/>
                    </Route>
                    <Route path="/property-detail/:id" element={<PropertyDetail/>}/>
                    <Route path="/" element={<Index/>}/>
                    <Route path="/co-trans/:id" element={<TransactionDetail/>}/>
                    <Route path="/co-trans/" element={<TransactionDetail/>}/>
                </Routes>
            </Router>
            <div className="disable-mobile">Website only available on desktop</div>
        </>
    );
};

export default App;
